






import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {RouteNames} from "@/router/types";
import TournamentInfoContainer from "@/components/Tournaments/TournamentInfo/TournamentInfoContainer.vue";

@Component({
  components: {TournamentInfoContainer},
  enums: {RouteNames}
})
export default class TournamentListCardContent extends Vue {
  @Prop({ type: Object, required: true, default: {} }) private readonly tournament!: ITournamentEntity
}
