












import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import TournamentJoinButton from "@/components/Tournaments/TournamentJoinButton.vue";
import TournamentSendAddRequestButton from "@/components/Tournaments/TournamentSendAddRequestButton.vue";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import {IAuthUser} from "@/core/auth/types";

@Component({
  components: {TournamentJoinButton, TournamentSendAddRequestButton}
})
export default class TournamentListCardFooter extends Vue {
  @Prop({ type: Object, required: true, default: {} }) private readonly tournament!: ITournamentEntity

  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  get isUserAlreadyExistInTournament () {
    return this.tournament.my_participation !== null
  }

  get currentUserIsOwner () {
    return this.tournament.owner_system_account_id === this.user.id
  }
}
