































import {Component, Vue, Watch} from 'vue-property-decorator'
import UserProfileCard from "@/components/UserProfile/UserProfileCard.vue";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {UserProfileModuleGetterTypes} from "@/store/modules/userProfile/types";
import {VuexState} from "@/core/vuex/types";
import CircularLoading from "@/components/Loading/CircularLoading.vue";
import {PopupsStoreModule, UserProfileStoreModule} from "@/store";
import UserProfileChallengerLifeStats from "@/components/UserProfile/Stats/UserProfileChallengerLifeStats.vue";
import UserProfileChallengerNightStats from "@/components/UserProfile/Stats/UserProfileChallengerNightStats.vue";
import UserProfileChallengerProStats from "@/components/UserProfile/Stats/UserProfileChallengerProStats.vue";
import {RouteNames} from "@/router/types";
import {PopupType} from "@/core/popups/types";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {AccountModuleGetterTypes} from "@/store/modules/account/types";
import ResponsiveContainer from "@/components/Shared/ResponsiveContainer.vue";
import UserProfileCurrentTournaments from "@/components/UserProfile/UserProfileCurrentTournaments.vue";

@Component({
  components: {
    UserProfileCurrentTournaments,
    ResponsiveContainer,
    UserProfileChallengerProStats,
    UserProfileChallengerNightStats, UserProfileChallengerLifeStats, CircularLoading, UserProfileCard},
  enums: {VuexState, RouteNames},
  beforeRouteEnter: (to, from, next) => {
    if (from.name === RouteNames.FriendInvitationRoute) {
      PopupsStoreModule.showPopup({
        popupType: PopupType.AddFriend,
      })
      next()
    } else {
      next()
    }
  }
})
export default class UserProfilePage extends Vue {
  @Getter(AuthModuleGetterTypes.IS_LOGGED_IN, { namespace: StoreNamespace.Auth })
  private readonly isLoggedIn!: boolean

  @Getter(AccountModuleGetterTypes.ACCOUNT, { namespace: StoreNamespace.Account })
  private readonly currentAccount!: IAccountEntity | null

  @Getter(UserProfileModuleGetterTypes.ACCOUNT_VUEX_STATE, { namespace: StoreNamespace.UserProfile })
  private readonly userProfileAccountState!: VuexState

  @Getter(UserProfileModuleGetterTypes.ACCOUNT, { namespace: StoreNamespace.UserProfile })
  private readonly userProfileAccount!: IAccountEntity | null

  get account (): IAccountEntity | null {
    if (this.isCurrentUserPage) {
      return this.currentAccount
    }

    return this.userProfileAccount
  }

  get isCurrentUserPage () {
    if (this.isLoggedIn && this.currentAccount)
      return this.currentAccount.id === this.$route.params.userId
    else
      return false
  }

  private mounted () {
    this.setUserProfile(this.$route.params)
  }

  @Watch('$route.params')
  private onRouteParamsChanged (newRouteParams: any) {
    this.setUserProfile(newRouteParams)
  }

  private setUserProfile (routeParams: any) {
    if (this.isLoggedIn) {
      if (this.isCurrentUserPage) {
        UserProfileStoreModule.initializeWithCurrentAccount()
      } else {
        UserProfileStoreModule.initialize(routeParams.userId)
      }
    } else {
      UserProfileStoreModule.initialize(routeParams.userId)
    }
  }
}
