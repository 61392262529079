

























import {Component, Prop, Vue} from 'vue-property-decorator'
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import UserProfileGameAccountsList from "@/components/UserProfile/UserProfileGameAccountsList.vue";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {AccountFriendsModuleGetterTypes} from "@/store/modules/account/friends/types";
import {find} from "lodash";
import {AccountFriendsModuleStoreModule, PopupsStoreModule} from "@/store";
import {PopupType} from "@/core/popups/types";
import {IDeleteFriendPopupData} from "@/components/Friends/DeleteFriendPopup.vue";
import BaseButtonRounded from "@/components/Shared/Buttons/BaseButtonRounded.vue"

@Component({
  components: {UserProfileGameAccountsList, BaseButtonRounded}
})
export default class UserProfileCard extends Vue {
  @Prop({ type: Object, required: true }) private readonly account!: IAccountEntity
  @Prop({ type: Boolean, required: true }) private readonly isCurrentUserPage!: boolean

  @Getter(AuthModuleGetterTypes.IS_LOGGED_IN, { namespace: StoreNamespace.Auth })
  private readonly isLoggedIn!: boolean

  @Getter(AccountFriendsModuleGetterTypes.FRIENDS, { namespace: StoreNamespace.AccountFriends })
  private readonly friends!: IAccountEntity[]

  get isCurrentUserIsFriend () {
    return !!find(this.friends, (friend: IAccountEntity) => friend.id === this.$route.params.userId);
  }

  private async onInviteToFriendAction () {
    const setInvitedAccountByInviteCodeResult = await AccountFriendsModuleStoreModule.setInvitedAccountByInviteCode(this.account.invitation_code as string)

    if (setInvitedAccountByInviteCodeResult.isSuccess()) {
      PopupsStoreModule.showPopup({
        popupType: PopupType.AddFriend,
      })
    }
  }

  private onDeleteFromFriendsAction (userId: string) {
    PopupsStoreModule.showPopup({
      popupType: PopupType.DeleteFriend,
      data: {
        friendId: userId
      } as unknown as IDeleteFriendPopupData
    })
  }
}
