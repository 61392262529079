



































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import TournamentSponsorAvatar from "../TournamentSponsorAvatar.vue";
import TournamentAccountAvatar from "../TournamentAccountAvatar.vue";
import {RouteNames} from "@/router/types";
import TournamentOrganizer from "@/components/Tournaments/TournamentOrganizer.vue";
import TournamentJoinButton from "@/components/Tournaments/TournamentJoinButton.vue";
import TournamentBackground from "@/components/Tournaments/TournamentBackground.vue";
import TournamentSendAddRequestButton from "@/components/Tournaments/TournamentSendAddRequestButton.vue";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import {IAuthUser} from "@/core/auth/types";
import TournamentPrivacyInfo from "@/components/Tournaments/TournamentPrivacyInfo.vue";
import TournamentTitle from "@/components/Tournaments/TournamentTitle.vue";

@Component({
  components: {
    TournamentTitle,
    TournamentPrivacyInfo,
    TournamentBackground,
    TournamentSendAddRequestButton,
    TournamentJoinButton,
    TournamentOrganizer, TournamentAccountAvatar, TournamentSponsorAvatar},
  enums: {RouteNames}
})
export default class TournamentListCardHeader extends Vue {
  @Prop({ type: Object, required: true, default: {} }) private readonly tournament!: ITournamentEntity

  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  get avatarSize (): number {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 50
      case "sm":
        return 66
      default:
        return 100
    }
  }

  get isUserAlreadyExistInTournament () {
    return this.tournament.my_participation !== null
  }

  get currentUserIsOwner () {
    return this.tournament.owner_system_account_id === this.user.id
  }
}
