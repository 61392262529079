



















import {Component, Prop} from "vue-property-decorator";
import {RouteNames} from "@/router/types";
import Vue from "vue";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import GameAccountListItem from "@/components/GameAccounts/GameAccountListItem.vue";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {AccountGameAccountsModuleGetterTypes} from "@/store/modules/account/gameAccounts/types";

@Component({
  components: {GameAccountListItem},
  enums: {RouteNames}
})
export default class UserProfileGameAccountsList extends Vue {
  @Prop({ type: Object, required: true }) private readonly account!: IAccountEntity
  @Prop({ type: Boolean, required: true }) private readonly isCurrentUserPage!: boolean

  @Getter(AccountGameAccountsModuleGetterTypes.GAME_ACCOUNTS, { namespace: StoreNamespace.AccountGameAccounts })
  private readonly currentAccountGameAccounts!: IAccountEntity

  get gameAccounts () {
    if (this.isCurrentUserPage) {
      return this.currentAccountGameAccounts
    }

    return this.account.gameAccounts
  }
}
