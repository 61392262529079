
















import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class UserProfileChallengerLifeStats extends Vue {

}
