















import {Component, Prop, Vue} from 'vue-property-decorator'
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {RouteNames} from "@/router/types";
import TournamentListCardHeader from "@/components/Tournaments/TournamentListItem/TournamentListCardHeader.vue";
import TournamentListCardContent from "@/components/Tournaments/TournamentListItem/TournamentListCardContent.vue";
import TournamentListCardFooter from "@/components/Tournaments/TournamentListItem/TournamentListCardFooter.vue";
import {Moment} from "moment";

@Component({
  components: {
    TournamentListCardFooter,
    TournamentListCardContent,
    TournamentListCardHeader
  },
  enums: {RouteNames}
})
export default class TournamentListCard extends Vue {
  @Prop({ type: Object, required: true, default: {} }) private readonly tournament!: ITournamentEntity
  @Prop({ type: Object, required: false }) private readonly dateTimeNow!: Moment
}
